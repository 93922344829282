import React from "react"
import ContentStations from "../components/ContentStations"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Stations = ({ pageContext }) => {
  return <ContentStations {...pageContext}></ContentStations>
}

export default Stations
